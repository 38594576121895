import React, { useEffect, useState, useRef } from "react"
import { Flex, Box } from "theme-ui"
import styles from '../theme/switch-style.css'
import { keyframes } from "@emotion/react"

const toggleBounce = keyframes({
  '0%': { transform: "translateX(0)" },
  '30%': { transform: "translateX(200%)" },
  '50%': { transform: "translateX(180%)" },
  '70%': { transform: "translateX(200%)" },
  '90%': { transform: "translateX(190%)" },
  '100%': { transform: "translateX(200%)" },
})

const DragSwitch = ({
	className,
	checked,
	onChange,
	onColor,
	offColor,
	handleColor,
	focusShadow,
	disabled,
	...labelProps
}) => {
	const switchRef = useRef(null)
	const [Xpos, setPos] = useState(checked ? 30 : 0)
	const [isMouseDown, setMouseDown] = useState(false)
	const [lastMouseUpTime, setMouseUpTime] = useState(0)
	const [hasOutline, setOutline] = useState(false)
	const [dragging, setDragging] = useState(false)
	const [dragEnd, setDragEnd] = useState(0)

	const handleChange = () => {
		// prevent setting checked if user was just dragging
		if (Date.now() - dragEnd > 25) {
			handleChecked(!checked)
		}
	}

	const handleChecked = (newChecked) => {
		if (newChecked) {
			setPos(30)
		} else {
			setPos(0)
		}

		if (newChecked !== checked) onChange(newChecked)
	}

	const startDrag = (e) => {
		//e.preventDefault()

		setMouseDown(true)
	}

	const onMouseUp = (e) => {
		setMouseDown(false)
		setMouseUpTime(Date.now())

		if (switchRef.current && dragging) {
			const mouseRelativePos = e.type == 'touchend' ?
        (e.changedTouches[0] ? e.changedTouches[0].clientX : 0) - switchRef.current.getBoundingClientRect().left:
				e.clientX - switchRef.current.getBoundingClientRect().left

			setDragEnd(Date.now())
			setDragging(false)
			if (mouseRelativePos >= 30) {
				handleChecked(true)
			} else {
				handleChecked(false)
			}
		}
	}

	const onMouseMove = (e) => {
		if (isMouseDown && !dragging) {
			setDragging(true)
		}

		if (switchRef.current && dragging) {
			const halfHandle = 8
			const newPos = e.type == 'touchmove' ?
        e.touches[0].clientX - switchRef.current.getBoundingClientRect().left - halfHandle :
			  e.clientX - switchRef.current.getBoundingClientRect().left - halfHandle

			if (newPos < 0) {
				setPos(0)
			} else if (newPos > 30) {
				setPos(30)
			} else {
				setPos(newPos)
			}
		}
	}

	useEffect(() => {
		// onMouseMove function relies on dragging and isMouseDown state
		window.addEventListener('mousemove', onMouseMove)
    window.addEventListener('touchmove', onMouseMove)

		return () => {
			window.removeEventListener('mousemove', onMouseMove)
      window.addEventListener('touchmove', onMouseMove)
		}
	}, [dragging, isMouseDown])

	useEffect(() => {
		// onMouseUp function relies on dragging state when setting value
		window.addEventListener('mouseup', onMouseUp)
    window.addEventListener('touchend', onMouseUp)

		return () => {
			window.removeEventListener('mouseup', onMouseUp)
      window.addEventListener('touchend', onMouseUp)
		}
	}, [dragging])

	return (
		<label
			style={{
				cursor: disabled ? 'not-allowed' : 'pointer',
				filter: disabled ? 'brightness(0.9)' : 'unset'
			}}
			{...labelProps}
			className={`switch`}
			ref={switchRef}
			onMouseDown={disabled ? undefined : startDrag}
      onTouchStart={disabled ? undefined : startDrag}
		>
			<div
				className={`switchBg ${checked ? 'isChecked' : ''}`}
				style={{
					backgroundColor: checked
						? onColor || '#66bb6a'
						: offColor || '#cccccc'
				}}
			/>
			<div
				id='dragswitch-handle'
				className={`switchHandle ${checked ? 'isChecked' : ''}`}
				style={{
					transform: `translateX(${Xpos}px)`,
					WebkitTransition: dragging ? undefined : 'transform .2s',
					MozTransition: dragging ? undefined : 'transform .2s',
					transition: dragging ? undefined : 'transform .2s',
					backgroundColor: handleColor || '#ffffff'
				}}
				onClick={e => {
					e.preventDefault()
					if (!disabled) handleChange()
				}}
			/>
			<input
				role='switch'
				aria-checked={checked}
				type='checkbox'
				defaultChecked={checked}
				onChange={handleChange}
				onFocus={() => {
					// prevent focus after user clicked/dragged switch
					if (Date.now() - lastMouseUpTime > 25) setOutline(true)
				}}
				disabled={disabled}
				className="switchInput"
			/>
		</label>
	)
}

const Toggle = (props) => {
  const [toggle, setToggle] = useState(false)

  useEffect(() => {
    if(toggle){
      setTimeout(() => {
        props.callback()
      }, 800)
    }
  }, [toggle])

  return (
    <Box mt={[2,2,0]}>
      <DragSwitch onChange={setToggle} checked={toggle} onColor={'#005DEA'} handleColor={toggle ? 'white' : '#3C3C3B'}/>
    </Box>
  )

  /*return (
    <Box sx={{
      transform: "scale(0.9)",
      span: {
        span: {
          transform: "scale(0.8)",
        }
      }
    }}>
      <Switch 
        checked={toggle}
        onChange={() => setToggle(!toggle)}
        onColor={"#005DEA"}
        offColor={"#D0D2D3"}
        handleColor={toggle ? "white": "#3C3C3B"}
      />
    </Box>
  )*/

  /*return (
    <Flex
      p="3px"
      mt={props.mt}
      mx={props.mx}
      sx={{
        ...props.sx,
        backgroundColor: toggle ? "#005DEA" : "#D0D2D3",
        borderRadius: "200px",
        width: "50px",
        cursor: "pointer",
        transition: "all 0.2s ease-in-out",
      }}
      onClick={() => setToggle(!toggle)}
    >
      <Box
        sx={{
          backgroundColor: toggle ? "white" : "#3C3C3B",
          transition: "all 0.2s ease-in-out",
          animation: toggle ? `${toggleBounce} 0.7s forwards` : '',
          transform: toggle ? "translateX(200%)" : "translateX(0px)",
          borderRadius: "200px",
          width: "33.33%",
          aspectRatio: "1/1",
        }}
      />
    </Flex>
  )*/
}

export default Toggle
