import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { Link } from "gatsby"
import React, { useEffect, useContext, useState, useRef } from "react"
import { Box, Flex } from "theme-ui"
import BgArrow from "../assets/bg-arrow.svg"
import BlondePerformanceLogo from "../assets/blonde-performance-logo.svg"
import Caret from "../assets/caret.png"
import Cursor from "../assets/ellipse.png"
import Logo from "../assets/performance-logo-illustration.svg"
import { InterfaceContext } from "../context/interface"
import "./base.css"

const Footer = ({ nav }) => {
  const { linkPrefix } = useContext(InterfaceContext)
  return (
    <Box
      sx={{
        mx: "auto",
        width: "100%",
        maxWidth: ["100%", "100%", "1800px"],
      }}
    >
      <Flex
        py={4}
        px={[4, 4, 0]}
        sx={{
          alignItems: "center",
          display: ["none", "none", "flex"],
          justifyContent: "space-between",
          a: {
            marginRight: "40px",
            textTransform: "uppercase",
            fontSize: "0.6rem",
          },
          "@media screen and (min-height: 900px)": {
            paddingBottom: "50px !important",
          },
        }}
      >
        <Flex>
          {nav.items.map((i, idx) => (
            <Link
              key={idx}
              to={i.slug.includes("http") ? i.slug : `/${linkPrefix}${i.slug}/`}
              style={{ textDecoration: "none" }}
            >
              {i.title}
            </Link>
          ))}
          <a href="https://blonde.inc" style={{ textDecoration: "none" }}>
            Blonde inc.
          </a>
        </Flex>
        <img style={{ width: "auto", height: "20px" }} src={Logo} />
      </Flex>
    </Box>
  )
}

const PerformanceLayout = ({ children, nav }) => {
  const { content, linkPrefix } = useContext(InterfaceContext)
  const [scrollPercentage, setScrollPercentage] = useState(0)
  const [fixHeader, setFixHeader] = useState(false)
  const [headerPos, setHeaderPos] = useState(0)
  const [translate, setTranslate] = useState({
    logo: "0%",
    subtitle: "0%",
  })
  const logoRef = useRef()
  const subtitleRef = useRef()
  const headerRef = useRef()

  useEffect(() => {
    setHeaderPos(headerRef.current.getBoundingClientRect().top)
  }, [])

  useScrollPosition(({ prevPos, currPos }) => {
    const scrollFromTop = currPos.y * -1
    const screenHeight = window.innerHeight - 50
    const percentage = Math.round(
      ((scrollFromTop - headerPos) / screenHeight) * 100
    )
    setScrollPercentage(percentage > 50 ? 50 : percentage <= 0 ? 0 : percentage)
    if (scrollFromTop > headerPos) {
      setFixHeader(true)
    } else {
      setFixHeader(false)
    }
  })

  useEffect(() => {
    if (!fixHeader) return
    const logoWidth = logoRef.current.offsetWidth
    const logoOffset = logoWidth * (scrollPercentage / 100)
    const subtitleWidth = subtitleRef.current.offsetWidth
    const subtitleOffset = subtitleWidth * (scrollPercentage / 100)
    setTranslate({
      logo: `calc(${scrollPercentage}% - ${logoOffset}px)`,
      subtitle: `calc(${scrollPercentage}% - ${subtitleOffset}px)`,
    })
  }, [scrollPercentage])

  return (
    <Flex
      px={[0, 0, "48px"]}
      sx={{
        fontFamily: "pragmatica-extended",
        minHeight: "100vh",
        minHeight: "-webkit-fill-available",
        flexDirection: "column",
        backgroundColor: "performance-dark",
        fontWeight: "100",
        color: "performance-light",
        position: "relative",
        p: {
          margin: "0",
        },
        cursor: `url(${Cursor}), auto`,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50px",
          right: "0",
          zIndex: "40",
        }}
      >
        <img style={{ width: "75px" }} src={BgArrow} />
      </Box>
      <Flex
        ref={headerRef}
        py={4}
        px={[4, 4, "48px"]}
        pt={[3, 3, 4]}
        pb={[3, 3, 3]}
        sx={{
          mx:"auto",
          maxWidth: ["100%", "100%", "1896px"],
          width: "100%",
          maxHeight: ["8vh", "8vh", "none"],
          position: [
            fixHeader ? "fixed" : "absolute",
            fixHeader ? "fixed" : "absolute",
            "fixed",
          ],
          background: "performance-dark",
          zIndex: "20",
          top: [fixHeader ? "0" : "75px", fixHeader ? "0" : "75px", "0"],
          left: "0",
          right: "0",
          "@media screen and (min-height: 900px)": {
            paddingTop: "50px !important",
          },
        }}
      >
        <Flex
          sx={{
            position: ["relative", "relative", "static"],
            alignItems: "flex-start",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Flex
            sx={{
              display: ["none", "none", "flex"],
              width: "100%",
              flexDirection: ["column", "column", "row"],
              justifyContent: "space-between",
            }}
          >
            <Link to={`/${linkPrefix}`} style={{ textDecoration: "none" }}>
              <Flex
                mb={3}
                sx={{
                  alignItems: "center",
                  justifyContent: "flex-start",
                  p: {
                    fontSize: "0.5rem",
                  },
                }}
              >
                <img
                  style={{
                    marginRight: "5px",
                    filter: "brightness(0) invert(1)",
                    transform: "rotate(180deg)",
                    width: "6px",
                  }}
                  src={Caret}
                />
                <p>{content.back_to_home}</p>
              </Flex>
            </Link>
            <Box
              sx={{
                p: {
                  fontSize: "0.5rem",
                },
              }}
            >
              <p>{content.blonde_tagline}</p>
            </Box>
          </Flex>
          <Box
            ref={logoRef}
            sx={{
              display: ["block", "block", "block"],
              position: ["relative", "relative", "static"],
              left: [translate.logo, translate.logo],
              height: ["15px", "15px", "25px"],
            }}
          >
            <img
              style={{ height: "100%", width: "auto" }}
              src={BlondePerformanceLogo}
            />
          </Box>
          <Box
            ref={subtitleRef}
            mt={1}
            sx={{
              display: ["block", "block", "none"],
              position: ["relative", "relative", "static"],
              left: [translate.subtitle, translate.subtitle],
              height: ["15px", "15px", "25px"],
              p: {
                fontSize: "0.5rem",
              },
            }}
          >
            <p>{content.blonde_tagline}</p>
          </Box>
        </Flex>
      </Flex>
      <Flex
        sx={{
          flexGrow: "1",
          flexDirection: "column",
        }}
      >
        {children}
      </Flex>
      <Footer nav={nav} />
    </Flex>
  )
}

export default PerformanceLayout
