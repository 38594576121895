import React, { useEffect, useRef, useState } from "react"
import { Flex, Box } from "theme-ui"
import Caret from "../assets/caret.png"

const FAQ = (props) => {
  const { items } = props
  const [active, setActive] = useState(-1)
  const [maxHeight, setMaxHeight] = useState(0)
  const contentRef = useRef([])

  const wrapper = useRef()

  useEffect(() => {
    const content = contentRef.current[active]

    if (!content) return
    const height = content.getBoundingClientRect().height
    setMaxHeight(height)
  }, [active])

  useEffect(() => {
    wrapper.current.style.height = `${wrapper.current.offsetHeight}px`
  }, [])

  return (
    <Flex ref={wrapper} sx={{ flexDirection: "column" }}>
      {items.map((item, idx) => {
        return (
          <Box
            key={idx}
            sx={{
              color: "performance-dark",
              borderBottom: "1px solid black",
              cursor: "pointer",
            }}
            onClick={() => (active == idx ? setActive(-1) : setActive(idx))}
          >
            <Flex
              mt={3}
              mb={0}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  fontSize: "1rem",
                }}
              >
                <p>{item.title}</p>
              </Box>
              <Box
                mr={2}
                sx={{
                  transform: active == idx ? "rotate(180deg)" : "rotate(90deg)",
                  transition: "all 100ms ease-out",
                }}
              >
                <img src={Caret} />
              </Box>
            </Flex>
            <Box
              mb={3}
              sx={{
                maxHeight: active == idx ? `${maxHeight}px` : "0",
                overflow: "hidden",
                transition: "all 100ms ease-out",
              }}
            >
              <Box
                pt={2}
                ref={(el) => (contentRef.current[idx] = el)}
                dangerouslySetInnerHTML={{ __html: item.content }}
              />
            </Box>
          </Box>
        )
      })}
    </Flex>
  )
}

export default FAQ
