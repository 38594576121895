import React, { useEffect, useRef, useState } from "react"
import { Flex, Box } from "theme-ui"
import BgArrow2 from "../assets/bg-arrow.svg"
import Logo from "../assets/performance-logo-illustration.svg"
import CaretToggle from "./caret-toggle"
import GrainOverlay from "./grain-overlay"
import ScrollDown from "./scroll-down"
import Navigation from "./performance-navigation"
import SliderDots from "./slider-dots"
import SliderItems from "./slider-items"
import { useDevice } from "use-device-react"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

const hijackScroll = () => {
  //console.log("hijacking scroll")
  //document.body.style.overflow = "hidden"
}

const unHijackScroll = () => {
  //console.log("unhijacking scroll")
  //document.body.style.overflow = "auto"
}

const sliderItemReducer = (acc, item) => {
  const sliderGroupIdx = acc.findIndex((i) => i.name == item.group)
  if (sliderGroupIdx != -1) {
    acc[sliderGroupIdx].slides.push(item)
  } else {
    acc.push({
      name: item.group,
      slides: [item],
    })
  }
  return acc
}

const sliderItemReducerMobile = (acc, item) => {
  const sliderGroupIdx = acc.findIndex((i) => i.name == item.group)
  const splitIntoTwo =
    item.layout[0] != "FAQ" && item.layout[0] != "Process"
    item.blocks.length > 3 &&
    !item.blocks.some((block) => block.internal.type == "ContentfulPerson")
  if (sliderGroupIdx != -1) {
    if (splitIntoTwo) {
      const firstSlide = {... item}
      const secondSlide = {... item}
      firstSlide.blocks = item.blocks.slice(0, 2) // First two blocks are put in first slide
      secondSlide.blocks = item.blocks.slice(2) // Rest of the blocks are put in the second slide
      acc[sliderGroupIdx].slides.push(firstSlide)
      acc[sliderGroupIdx].slides.push(secondSlide)
    } else {
      acc[sliderGroupIdx].slides.push(item)
    }
  } else {
    acc.push({
      name: item.group,
      slides: [item],
    })
  }
  return acc
}

const PerformanceSlider = ({
  sliderItems,
  collapse,
  setCollapse,
  isTransitioning,
  menu,
}) => {
  const { isDesktop } = (typeof window !== "undefined") ? useDevice() : {isDesktop: true}
  const [activeSlideGroup, setActiveSlideGroup] = useState(0)
  const [activeSlide, setActiveSlide] = useState(0)
  const [openMenu, setOpenMenu] = useState(false)
  const [activeSlideColor, setActiveSlideColor] = useState("Transparent")
  const [items, setItems] = useState(sliderItems.reduce(sliderItemReducer, []))
  const scrollBox = useRef()
  const lastSlide = items.reduce((acc, i) => acc + i.slides.length, 0) - 1

  
  useEffect(() => {
    const flattenedItems = items.map((item) => item.slides).flat()
    const itemStartPositions = flattenedItems.reduce((acc, item, idx) => {
      acc.push(scrollBox.current.offsetHeight * (idx))
      return acc
    }, [])
    const setActive = () => {
      const startPointer = scrollBox.current.scrollTop - 25
      const endPointer = scrollBox.current.scrollTop + scrollBox.current.offsetHeight - 25
      const active = itemStartPositions.findIndex((itemStartPos) => (itemStartPos > startPointer) && (itemStartPos < endPointer))
      setActiveSlide(active)
    }
    scrollBox.current.removeEventListener('scroll', setActive)
    scrollBox.current.addEventListener('scroll', setActive)
  }, [])

  const getActiveSlideGroup = (slideIdx) => {
    const slideToGroupIdx = items.reduce(
      (acc, item, idx) => {
        item.slides.forEach((slide) => {
          acc.map[acc.count] = idx
          acc.count++
        })
        return acc
      },
      {
        count: 0,
        map: {},
      }
    ).map
    return slideToGroupIdx[slideIdx]
  }

  const getActiveSlideColor = (activeSlideColor) => {
    switch (activeSlideColor) {
      case "Transparent":
        return "white"
      case "Dark":
        return "white"
      case "Light":
        return "performance-darkgrey"
    }
  }

  useEffect(() => {
    // On mobile we split up some slides into two slides
    if (isDesktop) {
      setItems(sliderItems.reduce(sliderItemReducer, []))
    } else {
      setItems(sliderItems.reduce(sliderItemReducerMobile, []))
    }
  }, [isDesktop])

  useEffect(() => {
    if (activeSlide == 0) {
      unHijackScroll()
    }
    /*const scrollAmount = scrollBox.current.offsetHeight * activeSlide
    scrollBox.current.scrollTo({
      top: scrollAmount,
      behavior: "smooth",
    })*/
    setActiveSlideGroup(getActiveSlideGroup(activeSlide))
    const flattenedItems = items.map((item) => item.slides).flat()
    setActiveSlideColor(flattenedItems[activeSlide]?.background[0])
  }, [activeSlide])

  useEffect(() => {
    if (!collapse) {
      unHijackScroll()
    }else{ // Add some delay before scrolling works in slider
      /*scrollBox.current.style.overflow = "hidden"
      setTimeout(() => { delay scrolling inside the slider
        scrollBox.current.style.overflow = "scroll"
      }, 1000)*/
    }
  }, [collapse])

  useEffect(() => {
    if (!collapse || isTransitioning || activeSlide == 0) return
    hijackScroll()
  }, [isTransitioning])

  return (
    <Box
      sx={{
        height: ["82vh", "82vh", "auto"],
        color: "performance-light",
        position: ["absolute", "absolute", "relative"],
        zIndex: "10",
        top: !collapse
          ? ["calc(100% - 10vh)", "calc(100% - 10vh)", "0"]
          : ["calc(10vh)", "calc(10vh)", "0"],
        borderTopLeftRadius: ["25px", "25px", "0"],
        borderTopRightRadius: ["25px", "25px", "25px"],
        width: ["100%", "100%", "calc(100% / 6 * 3)"],
        transition: "all 0.5s ease-in-out",
        transform: !collapse
          ? ["", "", "translateX(0)"]
          : ["", "", "translateX(calc(-100% / 6 * 4))"],
      }}
    >
      <Box
        sx={{
          display: ["block", "block", "none"],
          position: "absolute",
          height: ["92vh"],
          color: "white",
          zIndex: "10",
          bottom: "0",
          borderTopLeftRadius: ["25px", "25px", "0"],
          borderTopRightRadius: ["25px", "25px", "0"],
          background: "performance-lightblue",
          width: ["100%", "100%", "calc(100% / 6 * 3)"],
          transition: "all 0.5s ease-in-out",
          overflow: "hidden",
          transform: !openMenu ? ["translateY(92vh)"] : ["translateY(0)"],
        }}
        onClick={() => setOpenMenu(!openMenu)}
      >
        <Flex
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "2.5rem",
          }}
        >
          <Flex
            sx={{
              color: "#08295A",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "0.6rem",
              alignItems: "center",
              justifyContent: "center",
              p: {
                marginTop: "2px",
              },
            }}
          >
            <Flex
              sx={{
                flexDirection: "column",
                justifyContent: "center",
                marginLeft: "2rem",
                marginRight: "0.5rem",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "white",
                  height: "1px",
                  width: "16px",
                  transform: "rotate(45deg)",
                  position: "absolute",
                }}
              ></Box>
              <Box
                sx={{
                  backgroundColor: "white",
                  height: "1px",
                  width: "16px",
                  transform: "rotate(-45deg)",
                }}
              ></Box>
            </Flex>
            <p>Back to site</p>
          </Flex>
          <img style={{ width: "75px" }} src={BgArrow2} />
        </Flex>
        <Navigation menu={menu} />
        <Box
          sx={{
            width: "105%",
            position: "absolute",
            bottom: "0",
            zIndex: "-1"
          }}
        >
          <img style={{ opacity: "0.1", filter: "invert(1)" }} src={Logo} />
        </Box>
      </Box>
          
      {/* Desktop Caret */}
      <CaretToggle
        sx={{
          display: ["none", "none", "flex"],
          opacity: collapse ? "0" : "1",
          position: "absolute",
          flexDirection: "row-reverse",
          width: "350px",
          pointerEvents: collapse ? "none" : "initial",
          lineHeight: "2rem",
          zIndex: "20",
          height: "100%",
          paddingLeft: "50px",
          left: "0",
          top: "0",
          filter: "brightness(0) invert(1)",
          fontWeight: "100",
          fontSize: "2rem",
          textTransform: "normal",
        }}
        caret={{
          transform: "rotate(-180deg)",
          marginRight: "20px",
        }}
        text={"Get the elevator pitch"}
        onClick={() => setCollapse(!collapse)}
      />
      {/* Mobile Caret */}
      <CaretToggle
        sx={{
          opacity: collapse ? "0" : "1",
          position: "absolute",
          flexDirection: "column-reverse",
          zIndex: "20",
          top: "20px",
          pointerEvents: collapse ? "none" : "initial",
          left: "0",
          right: "0",
          filter: "brightness(0) invert(1)",
          fontWeight: "100",
        }}
        caret={{
          transform: "rotate(-90deg)",
          marginBottom: "5px",
        }}
        text={"Get the elevator pitch"}
        onClick={() => setCollapse(!collapse)}
      />
      <Box
        sx={{
          width: "100%",
          height: "100%",
          willChange: "transform",
          overflow: "hidden",
          position: "relative",
          borderTopLeftRadius: ["25px", "25px", "0"],
          borderTopRightRadius: ["25px", "25px", "0"],
        }}
      >
        <GrainOverlay />
        <Box
          sx={{
            position: "relative",
            zIndex: "5",
            width: "100%",
            height: "100%",
            transition: "all 0.2s ease-in-out",
            opacity: !collapse ? "0" : "1",
          }}
        >
          <Box
            onClick={() => setOpenMenu(!openMenu)}
            sx={{
              position: "absolute",
              top: "0",
              right: "0",
              left: "0",
              zIndex: "10",
              zIndex: "10",
              width: "34px",
              height: "14px",
              cursor: "pointer",
              margin: "20px auto 0 auto",
              display: ["block", "block", "none"],
            }}
          >
            <Box
              sx={{
                height: "1px",
                width: "100%",
                margin: "4px auto",
                transition: "all 0.3s ease-in-out",
                backgroundColor: getActiveSlideColor(activeSlideColor),
              }}
            ></Box>
            <Box
              sx={{
                height: "1px",
                width: "100%",
                margin: "4px auto",
                transition: "all 0.3s ease-in-out",
                backgroundColor: getActiveSlideColor(activeSlideColor),
              }}
            ></Box>
            <Box
              sx={{
                height: "1px",
                width: "100%",
                margin: "4px auto",
                transition: "all 0.3s ease-in-out",
                backgroundColor: getActiveSlideColor(activeSlideColor),
              }}
            ></Box>
          </Box>
          <ScrollDown
            disableText={true}
            disableLogo={true}
            invert={activeSlideColor == "Transparent" ? true : false}
            sx={{
              display: activeSlide == lastSlide ? "none" : "flex",
              position: "absolute",
              zIndex: "30",
              width: "100%",
              bottom: "30px",
              pointerEvents: "none",
            }}
          />
          <SliderDots
            items={items}
            setActiveSlide={setActiveSlide}
            activeSlideGroup={activeSlideGroup}
            activeSlideColor={activeSlideColor}
            sx={{
              display: ["none", "none", "flex"],
            }}
            scrollBox={scrollBox}
          />
          <SliderItems items={items} ref={scrollBox} />
        </Box>
      </Box>
    </Box>
  )
}

export default PerformanceSlider
