import React, { forwardRef, useContext, useState } from "react"
import { Flex, Box } from "theme-ui"
import FAQ from "./faq"
import Arrow from "../assets/large-arrow.svg"
import SliderProcess from "./slider-process"
import Lottie from "lottie-react"
import Caret from "../assets/caret.png"
import { InterfaceContext } from "../context/interface"

const getBackground = (slide) => {
  if (!slide.background) return
  switch (slide.background[0]) {
    case "Transparent":
      return "transparent"
    case "Dark":
      return "performance-dark"
    case "Light":
      return "#f9f9f9"
  }
}

const getTextColor = (slide) => {
  if (!slide.background) return
  switch (slide.background[0]) {
    case "Transparent":
      return "#fff"
    case "Dark":
      return "#fff"
    case "Light":
      return "performance-dark"
  }
}

const getAccentColor = (slide) => {
  if (!slide.background) return
  switch (slide.background[0]) {
    case "Transparent":
      return "#fff"
    case "Dark":
      return "performance-blue"
    case "Light":
      return "performance-blue"
  }
}

const headerStyle = {
  fontFamily: "pragmatica-extended",
  fontSize: ["1.5rem", "1.5rem", "2rem"],
  fontWeight: "300",
  textAlign: ["center", "center", "left"],
  mb: "4",
  lineHeight: ["2rem", "2rem", "2.5rem"],
}

const paragraphStyle = {
  fontFamily: "pragmatica-extended",
  textAlign: ["center", "center", "left"],
  whiteSpace: "pre-wrap",
  strong: {
    ...headerStyle,
    color: "performance-blue",
  },
}

const TextBlock = ({ sx, slide, html, enlarge }) => {
  return (
    <Box
      sx={{
        ...sx,
        ...paragraphStyle,
        fontSize: enlarge ? '16px' : '12px',
        h2: { fontSize: "1.5rem", fontFamily: "pragmatica-extended" },
        h3: {
          fontSize: "1.1rem",
          fontFamily: "pragmatica-extended",
          fontWeight: "100",
        },
        strong: { fontWeight: "400", color: getAccentColor(slide) },
      }}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  )
}

const SliderItem = ({ slide }) => {
  const slideTitle = slide.slideTitle.childMarkdownRemark.html

  const textBlocks = slide.blocks
    ? slide.blocks.reduce((acc, block) => {
        if (block.internal.type == "ContentfulTextColumn") {
          acc.push(block)
        }
        return acc
      }, [])
    : null

  const peopleBlocks = slide.blocks
    ? slide.blocks.reduce((acc, block) => {
        if (block.internal.type == "ContentfulPerson") {
          acc.push(block)
        }
        return acc
      }, [])
    : null

  switch (slide.layout[0]) {
    case "FAQ": {
      const items = slide.blocks.map((block) => {
        return {
          title: block.name,
          content: block.text.childMarkdownRemark.html,
        }
      })
      return (
        <Box>
          <Box
            sx={{
              ...paragraphStyle,
              ...headerStyle,
            }}
            dangerouslySetInnerHTML={{ __html: slideTitle }}
          />
          <FAQ items={items} />
        </Box>
      )
    }
    case "Process": {
      return (
        <SliderProcess
          slide={slide}
          textBlocks={textBlocks}
          paragraphStyle={paragraphStyle}
          headerStyle={headerStyle}
        />
      )
    }
    default: {
      return (
        <Box sx={{ width: "100%" }}>
          {slide.showTitleOnSlide && (
            <Box sx={{ position: "relative" }}>
              <Box
                sx={{
                  ...paragraphStyle,
                  ...headerStyle,
                }}
                dangerouslySetInnerHTML={{ __html: slideTitle }}
              />
              {slide.showArrow && (
                <Box
                  sx={{
                    pointerEvents: "none",
                    width: "400px",
                    display: ["none", "none", "block"],
                    position: "absolute",
                    left: "51%",
                    top: "25px",
                  }}
                >
                  <img src={Arrow} alt="" />
                </Box>
              )}
            </Box>
          )}
          {textBlocks &&
            textBlocks.length != 0 &&
            (textBlocks.length == 1 ? (
              textBlocks.map((block, idx) => {
                return (
                  <Flex
                    key={idx}
                    sx={{
                      textAlign: ["center", "center", "left"],
                      alignItems: ["center", "center", "flex-start"],
                      flexDirection: ["column", "column", "row"],
                    }}
                  >
                    {block.lottieAnimation?.internal?.content && (
                      <Box
                        sx={{
                          mr: "3",
                          aspectRatio: "1:1",
                          maxWidth: "75px",
                        }}
                      >
                        <Lottie
                          animationData={JSON.parse(
                            block.lottieAnimation.internal.content
                          )}
                          loop={true}
                        />
                      </Box>
                    )}
                    <TextBlock
                      sx={{
                        maxWidth: slide.showArrow ? "600px" : "none",
                      }}
                      enlarge={block.enlarge}
                      slide={slide}
                      html={block.text.childMarkdownRemark.html}
                    />
                  </Flex>
                )
              })
            ) : (
              <Flex sx={{ flexWrap: "wrap", rowGap: ["3", "3", "6"] }}>
                {textBlocks.map((block, idx) => {
                  return (
                    <Flex
                    key={idx}
                      sx={{
                        width: ["100%", "100%", "50%"],
                        pr: ["0", "0", "3"],
                        textAlign: ["center", "center", "left"],
                        alignItems: ["center", "center", "flex-start"],
                        flexDirection: ["column", "column", "row"],
                      }}
                    >
                      {block.lottieAnimation?.internal?.content && (
                        <Box
                          sx={{
                            mr: "3",
                            aspectRatio: "1:1",
                            maxWidth: "50px",
                          }}
                        >
                          <Lottie
                            animationData={JSON.parse(
                              block.lottieAnimation.internal.content
                            )}
                            loop={true}
                          />
                        </Box>
                      )}
                      <TextBlock
                        slide={slide}
                        html={block.text.childMarkdownRemark.html}
                      />
                    </Flex>
                  )
                })}
              </Flex>
            ))}

          {peopleBlocks.length > 0 && (
            <Flex
              sx={{
                flexDirection: ["column", "column", "row"],
                alignItems: ["center", "center", "flex-start"],
                textAlign: ["center", "center", "left"],
                mt: "4",
                columnGap: "6",
              }}
            >
              {peopleBlocks.map((block, idx) => {
                return (
                  <Flex
                  key={idx}
                    sx={{
                      flexDirection: "column",
                      alignItems: ["center", "center", "flex-start"],
                      mb: "4",
                    }}
                  >
                    <Box
                      sx={{ textTransform: "uppercase", fontWeight: "bold" }}
                    >
                      {block.name}
                    </Box>
                    <Box>{block.title}</Box>
                    <Box>{block.email}</Box>
                    <Box>M: {block.phone}</Box>
                  </Flex>
                )
              })}
            </Flex>
          )}
        </Box>
      )
    }
  }
}

const SliderItems = forwardRef(({ items }, ref) => {

  const {content} = useContext(InterfaceContext)

  return (
    <Flex
      ref={ref}
      sx={{
        height: "100%",
        width: "100%",
        flexDirection: "column",
        "::-webkit-scrollbar": {
          display: "none",
        },
        scrollbarWidth: "none",
        scrollSnapType: "y mandatory",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      {items.map((item, idx) => {
        return item.slides.map((slide, i) => {
          //console.log(slide)
          return (
            <Flex
              key={`${idx}-${i}`}
              p={4}
              pl={[32, 32, 164]}
              pr={[32, 32, 200]}
              sx={{
                scrollSnapAlign: "start",
                height: "100%",
                flexShrink: "0",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                background: getBackground(slide),
                color: getTextColor(slide),
              }}
            >
              <SliderItem slide={slide} />
              {idx == items.length - 1 && i == item.slides.length - 1 && (
                <Flex
                  sx={{
                    display: ["none", "none", "flex"],
                    flexDirection: "column",
                    alignItems: "flex-start",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    ref.current.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    })
                  }}
                >
                  <Box>
                    <img
                      style={{
                        margin: "0 auto",
                        marginLeft: "5px",
                        width: "8px",
                        transform: "rotate(-90deg)",
                        filter: "brightness(100)",
                      }}
                      src={Caret}
                    />
                  </Box>
                  <Box sx={{ textTransform: "uppercase" }}>{content.back_to_top}</Box>
                </Flex>
              )}
            </Flex>
          )
        })
      })}
    </Flex>
  )
})

export default SliderItems
