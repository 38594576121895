import React, { useState } from "react"
import { Flex, Box } from "theme-ui"

const processWrapperStyles = {
  position: "absolute",
  backgroundColor: "#f9f9f9",
  cursor: "pointer",
  justifyContent: "space-between",
  alignItems: "center",
  zIndex: "2",
  textAlign: "center",
}

const getItemStyle = (idx) => {
  switch (idx) {
    case 0:
      return {
        top: "-18px",
        width: ["160px", "160px", "180px"],
        paddingBottom: ["20px", "20px", "15px"],
        paddingTop: "2"
      }
    case 1:
      return {
        right: "-44px",
        paddingX: ["1", "1", "2"],
        width: "90px",
        height: "100px",
        flexDirection: "column",
      }
    case 2:
      return {
        bottom: "-18px",
        width: ["160px", "160px", "180px"],
        paddingBottom: "2",
        paddingTop: ["20px", "20px", "15px"]
      }
    case 3:
      return {
        left: "-44px",
        paddingX: ["1", "1", "2"],
        width: "90px",
        height: "100px",
        flexDirection: "column",
      }
  }
};

const Ball = () => {
  return (
    <Box
      sx={{
        borderRadius: "9999px",
        backgroundColor: "performance-blue",
        width: "15px",
        height: "15px",
      }}
    ></Box>
  )
}

const SliderProcess = ({slide, textBlocks, paragraphStyle, headerStyle}) => {
  const [activeProcess, setActiveProcess] = useState("none") 
  const slideTitle = slide.slideTitle.childMarkdownRemark.html;

  return (
    <Flex
      sx={{
        width: ["80%", "80%", "100%"],
        height: ["80%", "80%", "45%"],
        margin: ["0 auto"],
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {slide.showTitleOnSlide && (
        <Box sx={{ position: "relative", height: "100%", width: "100%" }}>
          <Flex
            sx={{
              ...paragraphStyle,
              ...headerStyle,
              margin: "0",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              p: {
                width: "60%",
                textAlign: "center",
                opacity: activeProcess == "none" ? "1" : "0",
                transition: "all 0.2s ease-in-out"
              }
            }}
            dangerouslySetInnerHTML={{__html: slideTitle}}
          >
          </Flex>
          
          {/* SLIDER ITEMS FOR PROCESS */}
              <Flex
                sx={{
                  position: "absolute",
                  top: "0",
                  width: "100%",
                  height: "100%"
                }}
              >
                {textBlocks.map((block, idx) => {
                  return (
                    
                      <Flex
                        key={idx}
                        sx={{
                          width: "100%",
                          height: "100%",
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                          position: "absolute",
                        }}
                      >
                        {/* Process items */}
                        <Flex
                          onClick={() => setActiveProcess(idx)}
                          onMouseEnter={() => setActiveProcess(idx)}
                          onMouseLeave={() => setActiveProcess("none")}
                          sx={{
                            ...processWrapperStyles,
                            ...getItemStyle(idx),
                          }}
                        >
                          {idx === 0 && (
                            <>
                              <Box
                                sx={{
                                  height: "100%",
                                  width: "10px",
                                  transform: "translateX(-8px)",
                                  opacity:
                                    activeProcess == "3" ||
                                    activeProcess == "none"
                                      ? "1"
                                      : "0.3",
                                }}
                              >
                                <Box
                                  sx={{
                                    height: "1px",
                                    width: "100%",
                                    backgroundColor: "black",
                                    transform: "rotate(40deg)",
                                    marginBottom: "5px",
                                  }}
                                ></Box>
                                <Box
                                  sx={{
                                    height: "1px",
                                    width: "100%",
                                    backgroundColor: "black",
                                    transform: "rotate(-40deg)",
                                  }}
                                ></Box>
                              </Box>
                              <Box
                                sx={{
                                  transition: "all 0.2s ease-in-out",
                                  opacity:
                                    activeProcess == idx ||
                                    activeProcess == "none"
                                      ? "1"
                                      : "0.3",
                                }}
                                dangerouslySetInnerHTML={{ __html: block.name }}
                              />
                              <Ball />
                            </>
                          )}
                          {idx === 1 && (
                            <>
                              {/* ARROW */}
                              <Flex
                                sx={{
                                  flexDirection: "column",
                                  marginLeft: "1px",
                                  width: "10px",
                                  transform: "translateY(-6px) rotate(90deg)",
                                  opacity:
                                    activeProcess == "0" ||
                                    activeProcess == "none"
                                      ? "1"
                                      : "0.3",
                                }}
                              >
                                <Box
                                  sx={{
                                    height: "1px",
                                    width: "100%",
                                    backgroundColor: "black",
                                    transform: "rotate(40deg)",
                                    marginBottom: "5px",
                                  }}
                                ></Box>
                                <Box
                                  sx={{
                                    height: "1px",
                                    width: "100%",
                                    backgroundColor: "black",
                                    transform: "rotate(140deg)",
                                  }}
                                ></Box>
                              </Flex>
                              <Box
                                sx={{
                                  transition: "all 0.2s ease-in-out",
                                  opacity:
                                    activeProcess == idx ||
                                    activeProcess == "none"
                                      ? "1"
                                      : "0.3",
                                }}
                                dangerouslySetInnerHTML={{ __html: block.name }}
                              />
                              <Ball />
                            </>
                          )}
                          {idx === 2 && (
                            <>
                              <Ball />
                              <Box
                                sx={{
                                  transition: "all 0.2s ease-in-out",
                                  opacity:
                                    activeProcess == idx ||
                                    activeProcess == "none"
                                      ? "1"
                                      : "0.3",
                                }}
                                dangerouslySetInnerHTML={{ __html: block.name }}
                              />
                              {/* ARROW */}
                              <Box
                                sx={{
                                  height: "100%",
                                  width: "10px",
                                  transform: "translateX(8px)",
                                  opacity:
                                    activeProcess == "1" ||
                                    activeProcess == "none"
                                      ? "1"
                                      : "0.3",
                                }}
                              >
                                <Box
                                  sx={{
                                    height: "1px",
                                    width: "100%",
                                    backgroundColor: "black",
                                    transform: "rotate(-40deg)",
                                    marginBottom: "5px",
                                  }}
                                ></Box>
                                <Box
                                  sx={{
                                    height: "1px",
                                    width: "100%",
                                    backgroundColor: "black",
                                    transform: "rotate(40deg)",
                                  }}
                                ></Box>
                              </Box>
                            </>
                          )}
                          {idx === 3 && (
                            <>
                              <Ball />
                              <Box
                                sx={{
                                  transition: "all 0.2s ease-in-out",
                                  opacity:
                                    activeProcess == idx ||
                                    activeProcess == "none"
                                      ? "1"
                                      : "0.3",
                                }}
                                dangerouslySetInnerHTML={{ __html: block.name }}
                              />
                              {/* ARROW */}
                              <Flex
                                sx={{
                                  flexDirection: "column",
                                  marginRight: "1px",
                                  width: "10px",
                                  transform: "translateY(6px) rotate(90deg)",
                                  opacity:
                                    activeProcess == "2" ||
                                    activeProcess == "none"
                                      ? "1"
                                      : "0.3",
                                }}
                              >
                                <Box
                                  sx={{
                                    height: "1px",
                                    width: "100%",
                                    backgroundColor: "black",
                                    transform: "rotate(140deg)",
                                    marginBottom: "5px",
                                  }}
                                ></Box>
                                <Box
                                  sx={{
                                    height: "1px",
                                    width: "100%",
                                    backgroundColor: "black",
                                    transform: "rotate(40deg)",
                                  }}
                                ></Box>
                              </Flex>
                            </>
                          )}
                        </Flex>
                        <Box
                          sx={{
                            transition: "all 0.2s ease-in-out",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                            color: "performance-blue",
                            textAlign: "center",
                            maxWidth: "60%",
                            opacity: activeProcess == idx ? "1" : "0",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: block.text.childMarkdownRemark.html,
                          }}
                        />
                      </Flex>
                    
                  )
                })}
              </Flex>
              {/* Transparent borders */}
              <Flex sx={{transition: "all 0.2s ease-in-out", opacity: activeProcess == "3" || activeProcess == "none"  ? "1" : "0.3", position: "absolute", top: "0", left: "0", flexWrap: "wrap", width: "50%", height: "50%", borderTop: "1px solid black", borderLeft: "1px solid black", borderTopLeftRadius: "25px"}}></Flex>
              <Flex sx={{transition: "all 0.2s ease-in-out", opacity: activeProcess == "0" || activeProcess == "none" ? "1" : "0.3", position: "absolute", top: "0", right: "0", flexWrap: "wrap", width: "50%", height: "50%", borderTop: "1px solid black", borderRight: "1px solid black", borderTopRightRadius: "25px"}}></Flex>
              <Flex sx={{transition: "all 0.2s ease-in-out", opacity: activeProcess == "2" || activeProcess == "none" ? "1" : "0.3", position: "absolute", bottom: "0", left: "0", flexWrap: "wrap", width: "50%", height: "50%", borderLeft: "1px solid black", borderBottom: "1px solid black", borderBottomLeftRadius: "25px"}}></Flex>
              <Flex sx={{transition: "all 0.2s ease-in-out", opacity: activeProcess == "1" || activeProcess == "none" ? "1" : "0.3", position: "absolute", bottom: "0", right: "0", flexWrap: "wrap", width: "50%", height: "50%", borderBottom: "1px solid black", borderRight: "1px solid black", borderBottomRightRadius: "25px"}}></Flex>
        </Box>
      )}
    </Flex>
  )
}

export default SliderProcess
