import React from "react"
import Caret from "../assets/caret.png"
import { Flex, Box } from "theme-ui"

const CaretToggle = (props) => {
  return (
    <Flex
      onClick={props.onClick}
      sx={{
        cursor: "pointer",
        display: ["flex", "flex", "none"],
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        transition: "0.2s ease-out",
        textTransform: "uppercase",
        fontWeight: "bold",
        fontSize: "0.6rem",
        ...props.sx,
      }}
    >
      <p>{props.text}</p>
      <Box sx={{ transform: "rotate(90deg)", ...props.caret }}>
        <img style={{ margin: "0 auto", width: "6px" }} src={Caret} />
      </Box>
    </Flex>
  )
}

export default CaretToggle
