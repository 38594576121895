import React, { useEffect, useContext, useState } from "react"
import { Link, navigate, graphql } from "gatsby"
import PerformanceLayout from "../components/performance-layout"
import PerformanceLanding from "../components/performance-landing"
import PerformanceFormWrapper from "../components/performance-form-wrapper"
import { InterfaceContext } from "../context/interface"

const PerformanceTemplate = ({ data, location, pageContext }) => {
  useEffect(() => {
    document.querySelector("html").style.background = "#000"
  })
  const { setLocale } = useContext(InterfaceContext)

  useEffect(() => {
    setLocale(pageContext.locale)
  }, [])

  return (
    <PerformanceLayout nav={data.pageContent.footer}>
      <PerformanceLanding data={data} />
      <PerformanceFormWrapper menu={data.pageContent.footer} data={data} />
    </PerformanceLayout>
  )
}

export default PerformanceTemplate

export const pageQuery = graphql`
  query PerformanceContent($slug: String!, $locale: String!) {
    pageContent: contentfulPerformance(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      requestAFreeSession {
        childMarkdownRemark {
          html
        }
      }
      title {
        childMarkdownRemark {
          html
        }
      }
      form {
        ...formFragment
      }
      slider {
        background
        showTitleOnSlide
        title
        group
        layout
        showArrow
        slideTitle {
          childMarkdownRemark {
            html
          }
        }
        blocks {
          ... on ContentfulPerson {
            ...personFragment
          }
          ... on ContentfulTextColumn {
            ...textColumnFragment
          }
        }
      }
      footer {
        ...navigationFields
      }
    }
  }
`
