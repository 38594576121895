import React, {useContext} from 'react'
import { Flex, Box } from "theme-ui"
import { Link } from "gatsby"
import { InterfaceContext } from "../context/interface"

const Navigation = ({ menu }) => {
    const { content, linkPrefix } = useContext(InterfaceContext)
    return (
      <Flex 
        p={4}
        sx={{
          alignItems: "center",
          display: ["flex", "flex", "none"],
          flexDirection: "column",
          a: {
            marginBottom: "2rem",
            textTransform: "uppercase",
            fontSize: "1.2rem"
          }
        }}
      >
        {menu.items.map((i,idx) => (
          <Link key={idx} to={i.slug.includes("http") ? i.slug : `/${linkPrefix}${i.slug}/`} style={{ textDecoration: "none" }}>{i.title}</Link>
        ))}   
        <Box>
          <a href="https://blonde.inc" style={{ textDecoration: "none" }}>Blonde inc.</a>
        </Box>
      </ Flex>
    ) 
  }
  export default Navigation