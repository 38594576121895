import React from 'react'
import Grain from "../assets/grain.jpg"
import { keyframes } from "@emotion/react"
import { Flex, Box } from "theme-ui"

import "../theme/gradient-style.css"

const animateGrain = keyframes`
  0%, 100% { transform:translate(0, 0) }
  10% { transform:translate(-5%, -10%) }
  20% { transform:translate(-2%, 5%) }
  30% { transform:translate(7%, -10%) }
  40% { transform:translate(-5%, 5%) }
  50% { transform:translate(-15%, 10%) }
  60% { transform:translate(15%, 0%) }
  70% { transform:translate(0%, 5%) }
  80% { transform:translate(3%, 10%) }
  90% { transform:translate(-5%, 5%) }
`

const animateGradient = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
}`

const GrainOverlay = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        inset: "0",
        zIndex: "1",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          inset: "0",
          zIndex: "2",
          background: `url(${Grain})`,
          mixBlendMode: "soft-light",
          opacity: "0.2",
          height: "300%",
          top: "-100%",
          left: "-50%",
          width: "300%",
          animation: `${animateGrain} 8s steps(10) infinite`,
        }}
      />
      <Box
        className="gradient-background"
        sx={{
          position: "absolute",
          inset: "0",
          zIndex: "1",
        }}
      />
    </Box>
  )
}

export default GrainOverlay