import React, { useContext, useEffect, useState } from "react"
import { Flex, Box } from "theme-ui"
import Toggle from "../components/toggle"
import ScrollDown from "../components/scroll-down"
import { InterfaceContext } from "../context/interface"

const PerformanceLanding = ({ data }) => {

  const {content} = useContext(InterfaceContext)

  return (
    <Flex
      px={4}
      mx="auto"
      sx={{
        minHeight: ["100vh", "100vh", "90vh"],
        minHeight: ["-webkit-fill-available", "-webkit-fill-available", "90vh"],
        minHeight: "100vh",
        position: "relative",
        maxWidth: "600px",
        flexGrow: "1",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        mb={2}
        sx={{
          fontSize: ["1.5rem", "1.5rem", "3.5rem"],
          lineHeight: ["2rem", "2rem", "4rem"],
          fontWeight: "100",
          textAlign: "center",
          whiteSpace: "pre-wrap",
          strong: {
            fontWeight: "100",
            color: "performance-blue",
          },
        }}
        dangerouslySetInnerHTML={{
          __html: data.pageContent.title.childMarkdownRemark.html,
        }}
      />
      <Flex
        mt={4}
        sx={{
          alignItems: "center",
          flexDirection: ["column", "column", "row"],
          columnGap: "4",
        }}
      >
        <Box
          sx={{
            p: { mb: "0" },
          }}
        >
          <p>{content.performance_unlock}</p>
        </Box>
        <Toggle
          mt={[2,2,0]}
          mx="auto"
          callback={() => {
            window.scrollTo({
              // Scroll to slightly above bottom
              top: document.body.scrollHeight - window.innerHeight - 10,
              behavior: "smooth",
            })
          }}
        />
      </Flex>
      <ScrollDown sx={{ position: "absolute", bottom: "25px" }} invert={true} />
    </Flex>
  )
}

export default PerformanceLanding
