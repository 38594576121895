import React, { useContext } from "react"
import { Flex, Box } from "theme-ui"
import Caret from "../assets/caret.png"
import Logo from "../assets/logo.svg"
import { keyframes } from "@emotion/react"
import { InterfaceContext } from "../context/interface"

const bounce = keyframes({
  from: { transform: "translateY(0)" },
  to: { transform: "translateY(5px)" },
})

const ScrollDown = (props) => {

  const {content} = useContext(InterfaceContext)
  return (
    <Flex
      sx={{
        ...props.sx,
        flexDirection: "column",
        alignItems: "center",
        transition: "all 0.5 ease-in-out",
        filter: props.invert ? "brightness(0) invert(1)" : "brightness(100) invert(1)",
        opacity: "0.75",
      }}
    >
      <img
        style={{
          margin: "6px 0",
          width: "50px",
          display: props.disableLogo ? "none" : "block",
        }}
        src={Logo}
      />
      <p style={{ display: props.disableLogo ? "none" : "block" }}>
        {content.scroll_down}
      </p>
      <Box sx={{ animation: `${bounce} 0.5s ease-in-out infinite alternate` }}>
        <img
          style={{ margin: "0 auto", width: "6px", transform: "rotate(90deg)"}}
          src={Caret}
        />
      </Box>
    </Flex>
  )
}

export default ScrollDown
