import React, { useState, useRef, useEffect, useCallback } from "react"
import { Flex, Box } from "theme-ui"
import PerformanceForm from "./performance-form"
import PerformanceSlider from "./performance-slider"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import BgArrow from "../assets/bg-arrow-2.svg"

const Divider = ({ collapse }) => {
  return (
    <Box
      sx={{
        pointerEvents: "none",
        display: ["none", "none", "block"],
        position: "absolute",
        inset: "0",
        zIndex: "20",
        width: "25%",
        transition: "all 0.5s ease-in-out",
        transform: collapse ? 'translateX(0)' : 'translateX(200%)',
      }}
    >
      <Box
        sx={{
          display: ["none", "none", "block"],
          position: "absolute",
          zIndex: "20",
          bottom: "-5px",
          width: "400px",
          right: "calc(0px + -199.5px)",
        }}
      >
        <img src={BgArrow} />
      </Box>
    </Box>
  )
}

const PerformanceFormWrapper = ({ data, formData, menu }) => {
  const [collapse, setCollapse] = useState(false)
  const [allowCollapse, setAllowCollapse] = useState(false) // Used to delay the slide from opening
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [hasReachedBottom, setHasReachedBottom] = useState(false)
  const form = useRef()

  const handleScrollAtBottom = useCallback((e) => {
    if(!e.deltaY || e.deltaY > 50){
      setCollapse(true)
    }
  }, [])

  useScrollPosition(() => {
    const bottomPos = Math.ceil(window.innerHeight + window.pageYOffset)
    if (bottomPos >= document.body.offsetHeight) {
      setHasReachedBottom(true)
      setTimeout(() => {
        setAllowCollapse(true)
      },500)
    } else if (hasReachedBottom) {
      setAllowCollapse(false)
      setCollapse(false)
      setHasReachedBottom(false)
    }
  })

  // Uncomment to automatically open the pitch deck when user scrolls to bottom
  /*useEffect(() => {
    if(allowCollapse){
      window.addEventListener('wheel', handleScrollAtBottom)
      window.addEventListener('touchmove', handleScrollAtBottom)

    }else{
      window.removeEventListener('wheel', handleScrollAtBottom)
      window.removeEventListener('touchmove', handleScrollAtBottom)
    }
  }, [allowCollapse])*/

  useEffect(() => {
    if (collapse) {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      })
      setIsTransitioning(true)
      setTimeout(() => {
        setIsTransitioning(false)
      }, 500)
    }
  }, [collapse])

  return (
    <Box
      mb={[0, 0, 4]}
      mt={[0, 0, 4]}
      mx={"auto"}
      sx={{
        width: "100%",
        maxWidth: ["100%", "100%", "1800px"],
        position: "relative",
      }}
    >
      <Divider collapse={collapse} />
      <Box
        sx={{
          flexGrow: "1",
          backgroundColor: "performance-grey",
          color: "performance-dark",
          borderRadius: ["0", "0", "25px"],
          borderTopLeftRadius: ["25px", "25px"],
          borderTopRightRadius: ["25px", "25px"],
          willChange: "transform",
          border: ["none", "none", "1px solid #D0D2D3"],
          overflow: "hidden",
        }}
        ref={form}
      >
        <Flex
          sx={{
            height: "100%",
            maxHeight: ["initial", "initial", "70vh"],
            flexDirection: ["column", "column", "row"],
            width: ["100%", "100%", "calc(100% / 4 * 6)"],
            position: "relative",
          }}
        >
          <PerformanceForm
            data={data}
            formData={formData}
            collapse={collapse}
            setCollapse={setCollapse}
          />
          <PerformanceSlider
            sliderItems={data.pageContent.slider}
            collapse={collapse}
            setCollapse={setCollapse}
            isTransitioning={isTransitioning}
            menu={menu}
          />
        </Flex>
      </Box>
    </Box>
  )
}

export default PerformanceFormWrapper
