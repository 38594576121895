import React from "react"
import { Flex, Box } from "theme-ui"

const getItemPosition = (items, item) => {
  return items.reduce((acc, i, idx) => {
    if (idx < items.findIndex((i) => i.name == item.name)) {
      acc = acc + i.slides.length
    }
    return acc
  }, 0)
}

const getActiveSlideColor = (activeSlideColor) => {
  switch (activeSlideColor) {
    case "Transparent":
      return "white"
    case "Dark":
      return "white"
    case "Light":
      return "performance-darkgrey"
  }
}

const SliderDots = ({
  items,
  setActiveSlide,
  activeSlideGroup,
  sx,
  activeSlideColor,
  scrollBox
}) => {
  return (
    <Flex
      sx={{
        flexDirection: "column",
        position: "absolute",
        right: "0",
        height: "100%",
        justifyContent: "center",
        alignItems: "flex-end",
        color: getActiveSlideColor(activeSlideColor),
        ...sx,
      }}
    >
      {items.map((item, idx) => {
        return (
          <Flex
            key={idx}
            py={1}
            sx={{
              cursor: "pointer",
              alignItems: "center",
            }}
            onClick={() => {
              const active = getItemPosition(items, item)
              const scrollAmount = scrollBox.current.offsetHeight * active
              scrollBox.current.scrollTo({
                top: scrollAmount,
                behavior: "smooth",
              })
              setActiveSlide(active)
            }}
          >
            <Box
              pr={2}
              sx={{
                transition: "all 0.2s ease-in-out",
                opacity: idx == activeSlideGroup ? "1" : "0.25",
              }}
            >
              {item.name}
            </Box>
            <Box
              sx={{
                width: "15px",
                height: "3px",
                background: getActiveSlideColor(activeSlideColor),
                borderRadius: "100px",
                marginLeft: "5px",
                transition: "all 0.2s ease-in-out",
                opacity: idx == activeSlideGroup ? "1" : "0",
              }}
            ></Box>
          </Flex>
        )
      })}
    </Flex>
  )
}

export default SliderDots
