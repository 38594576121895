import React, { useEffect, useState } from "react"
import { Flex, Box, Button, Checkbox, Label, Input } from "theme-ui"
import WigglyArrow1 from "../assets/wiggly-arrow-1.svg"
import WigglyArrow2 from "../assets/wiggly-arrow-2.svg"
import CaretToggle from "./caret-toggle"
import { useFormik } from "formik"
import { navigate } from "gatsby"
import Caret from "../assets/caret.png"
import * as Yup from "yup"
import FormModule from "./form/form-module"

const SubmitButton = ({ text, formik }) => (
  <Button
    mt={3}
    px={0}
    py={0}
    sx={{
      opacity: formik.isValid ? "1" : "0.5",
      cursor: formik.isValid ? "pointer" : "not-allowed",
      borderRadius: 0,
      background: "none",
      color: "performance-dark",
      fontWeight: "bold",
      fontFamily: "pragmatica-extended",
      lineHeight: "1rem",
      textTransform: "uppercase",
    }}
    type="submit"
  >
    <Flex sx={{ alignItems: "center", columnGap: "2" }}>
      <Box>{text}</Box>
      <Box>
        <img
          style={{ margin: "0 auto", width: "6px", filter: "brightness(0)" }}
          src={Caret}
        />
      </Box>
    </Flex>
  </Button>
)

const PerformanceForm = ({ setCollapse, collapse, data }) => {
  return (
    <Flex
      pt={[32, 32, 0]}
      pb={[64, 64, 0]}
      sx={{
        alignItems: ["flex-start", "flex-start", "center"],
        width: ["100%", "100%", "calc(100% / 6 * 3)"],
        height: ["92vh", "92vh", "auto"],
        overflowY: ["scroll", "scroll", "auto"],
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        "::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <CaretToggle
        sx={{
          opacity: !collapse ? "0" : "1",
          position: "absolute",
          zIndex: "10",
          top: "20px",
          left: "0",
          right: "0",
        }}
        caret={{
          transform: "rotate(90deg)",
        }}
        text={"Request a free session"}
        onClick={() => setCollapse(!collapse)}
      />
      <Flex
        sx={{
          maxHeight: ["none", "none", "100%"],
          flexDirection: ["column", "column", "row"],
          opacity: collapse ? ["0", "0", "1"] : "1",
          transform: collapse
            ? ["translateX(0)", "translateX(0)", "translateX(-50%)"]
            : "translateX(0)",
          transition: "0.5s ease-in-out",
        }}
      >
        <Box
          py={[0, 0, 64]}
          pr={[32, 32, 128]}
          pl={[32, 32, 64]}
          sx={{
            position: "relative",
            width: ["100%", "100%", "50%"],
          }}
        >
          <Box
            sx={{ maxWidth: ["none", "none", "375px"], position: "relative" }}
          >
            <Box
              sx={{
                whiteSpace: "pre-wrap",
                position: "relative",
                zIndex: "10",
                h2: {
                  fontSize: ["1.5rem", "1.5rem", "2.5rem"],
                  lineHeight: "normal",
                  fontWeight: "100",
                },
                strong: {
                  fontWeight: "400",
                },
                p: {
                  display: "inline-block",
                  backgroundColor: "performance-grey",
                  marginBottom: "10px",
                },
              }}
              dangerouslySetInnerHTML={{
                __html:
                  data.pageContent.requestAFreeSession.childMarkdownRemark.html,
              }}
            />
            <Box
              sx={{
                display: ["block", "block", "none"],
                position: "relative",
                bottom: "18px",
                "@media screen and (max-height: 667px)": {
                  display: "none",
                },
              }}
            >
              <img alt="arrow" src={WigglyArrow2} />
            </Box>
            <Box
              sx={{
                display: ["none", "none", "block"],
                position: "absolute",
                right: "-100px",
                bottom: "20px",
              }}
            >
              <img alt="arrow" src={WigglyArrow1} />
            </Box>
          </Box>
        </Box>
        <FormModule
          py={[16, 16, 64]}
          pl={32}
          pr={!collapse ? [32, 32, 64] : 32}
          sx={{
            overflowY: "scroll",
            transition: "0.5s ease-in-out",
            width: ["100%", "100%", collapse ? "calc(100% / 3)" : "50%"],
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
          formData={data.pageContent.form}
          color="#000000"
        />
      </Flex>
    </Flex>
  )
}

export default PerformanceForm
